import React from 'react'
import PropTypes from "prop-types"
import Img from 'gatsby-image'
import Layout from "../components/Layout"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SEO from "../components/seo"
import default_image from '../images/default_back.jpg'
class NotFoundPage extends React.Component{
    render(){
        return(
            <Layout>
            <SEO title="EROR 404" keywords={[`telekomunikacije`, `inžinjering`]} />
            <div id={"hvala_vam"} className="post">
                <section className="section page-title default" style={{ backgroundImage: `url(${default_image})` }}>
                    <div className="container is-fluid">
                    <h1 className="title">EROR 404</h1>
                    </div>
                </section>

                <section className="page-news-header">
                    <div className="container is-fluid">
                       

                        <div className="is-right share">
                            <a href=""><FontAwesomeIcon icon={['fab', 'facebook-f']} aria-hidden="true" /></a>
                            <a href=""><FontAwesomeIcon icon={['fab', 'twitter']} aria-hidden="true" /></a>
                            <a href=""><FontAwesomeIcon icon={['fas', 'envelope']} aria-hidden="true" /></a>
                        </div>
                    </div>
                </section>

                <section className="section page-content">
                   <div className="container is-fluid">
                        <p className="under-construction">Žao nam je, stranica kojoj ste pokušali pristupiti nije pronađena. Molimo Vas da koristite navigaciju na vrhu stranice,
                        kako bi došli do željenog sadržaja.</p>
                   </div>
                </section>
            </div>
            </Layout>
        )
    }
}



export default NotFoundPage
